import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import ColorNavbarMain from "components/Navbars/ColorNavbarMain.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import SectionIndexContactUs from "views/sections-sections/SectionIndexContactUs";
import FooterBlackMain from "components/Footers/FooterBlackMain.js";
import SectionFeatureMain from "views/sections-sections/SectionFeatureMain.js";
// sections for this page
// import SectionButtons from "./index-sections/SectionButtons.js";
// import SectionNavigation from "./index-sections/SectionNavigation.js";
// import SectionNavbars from "./index-sections/SectionNavbars.js";
// import SectionCards from "./index-sections/SectionCards.js";
// import NucleoIcons from "views/NucleoIcons";;

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <>
      <ColorNavbarMain />
      <div className="main">
        <IndexHeader />
        <SectionIndexContactUs />
        <SectionFeatureMain />
        <FooterBlackMain />
      </div>
    </>
  );
}

export default Index;
