import React from "react";
// react plugin used to create google maps
import {
  Card,
  CardHeader, // eslint-disable-line
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

function SectionIndexContactUs() {
  return (
    <>
      <div
        className="section section-contactus cd-section"
        id="contact-us"
        style={{ paddingTop: 0, background: "none" }}
      >
        {/* ********* CONTACT US 1 ********* */}
        <div className="contactus-1 section-image">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    <span id="contact">Contact Us</span>
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Richard H. Smith</h4>
                            <p>
                              <b>
                                Director
                                <br />
                                M.A. L.P.C. M.S.W.
                                <br />
                              </b>
                            </p>
                            <br />
                            <p>
                              <a
                                href="mailto:r28206@me.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i
                                  className="nc-icon nc-email-85"
                                  style={{
                                    fontSize: "13px",
                                    marginRight: "0.5rem",
                                  }}
                                />{" "}
                                <b>r28206@me.com</b>
                              </a>
                              <br />
                              <i
                                className="nc-icon nc-mobile"
                                style={{
                                  fontSize: "13px",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <b>248-398-7061</b>
                              <br />
                              <i
                                className="nc-icon nc-paper"
                                style={{
                                  fontSize: "13px",
                                  marginRight: "0.5rem",
                                }}
                              />{" "}
                              <b>248-398-0231</b>
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="mr-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Michael A. Conat</h4>
                            <p>
                              <b>
                                Clinical Counselor <br />
                                M.A. L.L.P.C. <br />
                              </b>
                            </p>
                            <br />
                            <p>
                              <a
                                href="mailto:michaelconat@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i
                                  className="nc-icon nc-email-85"
                                  style={{
                                    fontSize: "13px",
                                    marginRight: "0.5rem",
                                  }}
                                />{" "}
                                <b>michaelconat@gmail.com</b>
                              </a>
                              <br />
                              <i
                                className="nc-icon nc-mobile"
                                style={{
                                  fonSize: "13px",
                                  marginRight: "0.5rem",
                                }}
                              />{" "}
                              <b>248-770-8338</b>
                              <br />
                              <i
                                className="nc-icon nc-paper"
                                style={{
                                  fontSize: "13px",
                                  marginRight: "0.5rem",
                                }}
                              />{" "}
                              <b>248-398-0231</b>
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* ********* END CONTACT US 1 ********* */}
      {/* ********* CONTACT US 2 ********* */}
      {/* <div className="contactus-2">
          <Container>
            <Col md="6">
              <Card className="card-contact card-raised">
                <CardHeader className="header-raised header-primary text-center">
                  <CardTitle tag="h4">Contact Us</CardTitle>
                </CardHeader>
                <Form id="contact-form2" method="post" role="form">
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h5 className="info-title">Give us a call</h5>
                            <p>
                              Michael Jordan <br />
                              +40 762 321 762 <br />
                              Mon - Fri, 8:00-22:00
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-pin-3" />
                          </div>
                          <div className="description">
                            <h5 className="info-title">
                              Find us at the office
                            </h5>
                            <p>
                              Bld Mihail Kogalniceanu <br />
                              7652 Bucharest
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="control-label">Full Name</label>
                          <Input
                            name="name"
                            placeholder="Full name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="control-label">Email address</label>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label className="control-label">Your message</label>
                      <Input
                        id="messages"
                        name="message"
                        placeholder="Message"
                        type="textarea"
                        rows="6"
                      />
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox" />
                            I'm not a robot !{" "}
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <Button
                          className="btn-rose pull-right"
                          color="default"
                          type="submit"
                        >
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        ********* END CONTACT US 2 ********* */}
      {/* </div> */}
    </>
  );
}

export default SectionIndexContactUs;
